import React, { useContext, useEffect, useState } from 'react';
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages, PageDesignConstants } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, EligibilityCriteria_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from "../../Helpers/Utilities";
import { EligibilityCriteriaService } from '../../WebApiServices/EligibilityCriteria.service';

const ExclusionCriteria = () => {

    const [isEligible, setIsEligible] = useState(false);
    const [isAllDataFilled, setIsAllDataFilled] = useState(false);

    const pid = GetLocalStorageData("patientId") ?? 0;
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);
    const eligibilityCriteriaLocked = patientData?.eligibilityCriteriaLocked ?? false;

    const navigatePaths = {
        prevPage: "/RRMMCriteria",
        currPage: "/ExclusionCriteria",
        nextPage: eligibilityCriteriaLocked?"/TransitionToChartAbstraction": (isAllDataFilled && isEligible) ? "/TransitionToChartAbstraction" : (isAllDataFilled && !isEligible) ? "/ManagePatients" : "/ExclusionCriteria",
    };

    const centerId = patientData?.ptCenterId ?? 0;
    const [ptEligibilityId, setPtEligibilityId] = useState(null);
    const [eligibilityDisabled, seteligibilityDisabled] = useState(false);

    const { ShowToast, ToggleLoader, HandleSessionTimeout,
        isSideMenuActive,
        setIsSideMenuActive, } = useContext(AppContext);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);

    useEffect(() => {
        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {
                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData();
    }, []);

    const RepeaterRow = {
        exclusionCriteriaId: null,
        cohortTreatmentId: 0,
        cohortTreatmentName: "", cohortTreatmentNameSuperscript: "",
        s13: null,
        s14: null,
        s13_hard_err: false,
        s14_hard_err: false,
        s13_soft_err: false,
        s14_soft_err: false,
    };



    const [criteriaExclusion, setCriteriaExclusion] = useState([RepeaterRow]);
    const [metInclusionCriteria, setMetInclusionCriteria] = useState(null);



    async function LoadData() {
        try {
            if (pid > 0) {
                const response = await EligibilityCriteriaService.LoadExclusionCriteria(pid);

                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;

                    setMetInclusionCriteria(data.metInclusionCriteria);
                    setPtEligibilityId(data.patientEligibilityId);
                    seteligibilityDisabled(data.eligibilityLocked);

                    const storedData = GetLocalStorageData("CohortTreatment");

                    if (storedData) {
                        let cohortTreatmentList = [];
                        cohortTreatmentList = JSON.parse(storedData);

                        //Cohort treatments present
                        if (cohortTreatmentList && cohortTreatmentList.length > 0) {

                            let exclusionCriteriaList = [];
                            //data present in db
                            if (data.exclusionCriteriaList && data.exclusionCriteriaList.length > 0) {
                                exclusionCriteriaList = cohortTreatmentList.map(ct => {

                                    let exclusionCriteriaId = null;
                                    let s13 = null;
                                    let s14 = null;

                                    //Check if data is present for a cohort tmt
                                    const dbRow = data.exclusionCriteriaList.find(a => a.cohortTreatmentId === ct.cohortTreatmentID)
                                    if (dbRow) {
                                        exclusionCriteriaId = dbRow.exclusionCriteriaId;
                                        s13 = dbRow.responseassessmentNdmmcart ? 1 : dbRow.responseassessmentNdmmcart === false ? 0 : null
                                        s14 = dbRow.clinicaltrialNdmmcart ? 1 : dbRow.clinicaltrialNdmmcart === false ? 0 : null
                                    }
                                    return {
                                        ...RepeaterRow,
                                        exclusionCriteriaId,
                                        cohortTreatmentId: ct.cohortTreatmentID,
                                        cohortTreatmentName: (ct.cohortTreatmentName === "DKRd (TE or TIE)") ? (ct.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (ct.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : ct.cohortTreatmentName)) : ct.cohortTreatmentName,
                                        cohortTreatmentNameSuperscript: ct.cohortTreatmentNameSuperscript,
                                        s13,
                                        s14,
                                        s13_soft_err: s13 === 0,
                                        s14_soft_err: s14 === 1
                                    };
                                })
                            } else {
                                exclusionCriteriaList = cohortTreatmentList.map(ct => ({
                                    ...RepeaterRow,
                                    cohortTreatmentId: ct.cohortTreatmentID,
                                    cohortTreatmentName: ct.cohortTreatmentName,
                                    cohortTreatmentNameSuperscript: ct.cohortTreatmentNameSuperscript
                                }))
                            }
                            setCriteriaExclusion(exclusionCriteriaList);
                        }
                    }

                    //set CohortTreatment in Local storage
                    if (data.cohortTreatmentModelList.length > 0)
                        AddLocalStorageData("CohortTreatment", JSON.stringify(data.cohortTreatmentModelList))
                    else
                        AddLocalStorageData("CohortTreatment", JSON.stringify([]))



                } else if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }

    const HandleRepeaterFieldChange = (e) => {
        const { name, value, id } = e.target;
        const valueInt = Number(value);
        const index = Number(id);

        let updatedArray = [...criteriaExclusion];
        if (name.startsWith("S13_EXCLUSION")) {
            updatedArray[index].s13 = valueInt;
            updatedArray[index].s13_hard_err = false;
            updatedArray[index].s13_soft_err = valueInt === 0;
        } else if (name.startsWith("S14_EXCLUSION")) {
            updatedArray[index].s14 = valueInt;
            updatedArray[index].s14_hard_err = false;
            updatedArray[index].s14_soft_err = valueInt === 1;
        }
        setCriteriaExclusion(updatedArray);
    };

    const ValidateForm = () => {
        let result = true;
        if (criteriaExclusion && criteriaExclusion.length > 0) {

            const validExclusionCriteria = criteriaExclusion.map(row => {

                const s13_hard_err = row.s13 == null;
                const s14_hard_err = row.s14 == null;

                if (s13_hard_err || s14_hard_err) {
                    result = false;
                }
                return {
                    ...row,
                    s13_hard_err,
                    s14_hard_err
                }

            });
            setCriteriaExclusion(validExclusionCriteria)
        }

        setIsAllDataFilled(result);
        return result;
    };

    function IsTerminated() {
        // Check if all cohorts are ineligible (s13 no OR s14 yes)
        return criteriaExclusion.every(item => item.s13 === 0 || item.s14 === 1)
    }

    const SavePage = async () => {
        try {
            ToggleLoader(true);

            if (!ValidateForm()) {
                setShowTopErrMsg(true)
                ToggleLoader(false);
                return false;
            }

            setShowTopErrMsg(false);
            const isIncluded = !IsTerminated();
            setMetInclusionCriteria(isIncluded);

            const exclusionCriteriaData = criteriaExclusion.map((item) => ({
                ExclusionCriteriaId: item.exclusionCriteriaId ?? 0,
                CohortTreatmentId: item.cohortTreatmentId,
                ResponseassessmentNdmmcart: item.s13,
                ClinicaltrialNdmmcart: item.s14,
            }));

            const exclusionCriteriaModel = {
                PatientId: pid,
                CenterId: centerId,
                PatientEligibilityId: ptEligibilityId,
                UserId: GetLoggedInUserID(),
                MetInclusionCriteria: isIncluded,
                ExclusionCriteriaList: exclusionCriteriaData,
            };
            setIsEligible(isIncluded);

            if (pid > 0) {
                let response = await EligibilityCriteriaService.SaveExclusionCriteria(exclusionCriteriaModel);

                if (response.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    } else {
                        throw response.error;
                    }
                } else {
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    await LoadData();

                    if (isIncluded)
                        navigatePaths.nextPage = '/TransitionToChartAbstraction'
                }

                ToggleLoader(false);
            }
            return true;

        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.ExclusionCriteria} />
                    
                        <>
                        <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                                </>

                    <div className="col-pad-left eligibiltylocked" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>ADDITIONAL EXCLUSION CRITERIA</h4>
                            </div>

                            {!showTopErrMsg && pid != 0 && metInclusionCriteria != null && (
                                metInclusionCriteria == false &&
                                (<div className="clean-error">
                                    <p>
                                        {EligibilityCriteria_Msgs.IneligiblePatient}
                                    </p>
                                </div>)
                            )}


                            {showTopErrMsg && (
                                <div className="clean-error">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}
                            <div className="survey-question-section" disabled={eligibilityDisabled}>
                                <div className="survey-question-content">
                                    <div className="question-answer">
                                        {criteriaExclusion && criteriaExclusion.length > 0 && criteriaExclusion.map((test, index) => (
                                            <div className="dependent-section" key={index}>
                                                <div className='cohort-header'>
                                                    <span>
                                                        {test.cohortTreatmentName}
                                                        {!isStringEmpty(test.cohortTreatmentNameSuperscript) && (
                                                        <>
                                                            <sup className="supscript">{test.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                            {test.cohortTreatmentNameSuperscript.slice(2)}
                                                        </>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="question-bottom-space">
                                                    <div className="question question-weight">
                                                        <span>S13.</span>
                                                        <span className="quest-text-pad">
                                                            Did patient receive ≥1 response assessment (e.g., partial response, complete response, etc.) after initiating
                                                            {" "}<span style={{ color: PageDesignConstants.fontColor }}>
                                                                {test.cohortTreatmentName}
                                                                {test.cohortTreatmentNameSuperscript == null ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <sup className="supscript">{test.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                        {test.cohortTreatmentNameSuperscript.slice(2)}
                                                                    </>
                                                                )}
                                                            </span>{" "}
                                                            ?
                                                        </span>
                                                    </div>
                                                    <div className="double-dig-answer">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id={index}
                                                                    name={"S13_EXCLUSION" + index}
                                                                    value={1}
                                                                    checked={test.s13 === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id={index}
                                                                    name={"S13_EXCLUSION" + index}
                                                                    value={0}
                                                                    checked={test.s13 === 0}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={test.s13_soft_err} message={EligibilityCriteria_Msgs.CohortIneligible} />
                                                    <ErrorField show={test.s13_hard_err} message={EligibilityCriteria_Msgs.s13} />
                                                </div>
                                                <div className="question-bottom-space">
                                                    <div className="question question-weight">
                                                        <span>S14.</span>
                                                        <span className="quest-text-pad">
                                                            Did the patient receive any part of
                                                            {" "}<span style={{ color: PageDesignConstants.fontColor }}>
                                                                {test.cohortTreatmentName}
                                                                {test.cohortTreatmentNameSuperscript == null ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <sup className="supscript">{test.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                        {test.cohortTreatmentNameSuperscript.slice(2)}
                                                                    </>
                                                                )}
                                                            </span>{" "}
                                                            as part of a clinical trial or expanded access program (EAP)?
                                                        </span>
                                                    </div>
                                                    <div className="double-dig-answer">
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id={index}
                                                                    name={"S14_EXCLUSION" + index}
                                                                    value={1}
                                                                    checked={test.s14 === 1}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div className="option-without-input">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id={index}
                                                                    name={"S14_EXCLUSION" + index}
                                                                    value={0}
                                                                    checked={test.s14 === 0}
                                                                    onChange={HandleRepeaterFieldChange}
                                                                />
                                                                <span className="radio-text-padding">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={test.s14_soft_err} message={EligibilityCriteria_Msgs.CohortIneligible} />
                                                    <ErrorField show={test.s14_hard_err} message={EligibilityCriteria_Msgs.s14} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ExclusionCriteria;
