import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import { SideMenuItems, HTTPResponse, ToastMessageType } from "../../Helpers/Enums";
import PatientDetails from "../../Components/PatientInformation";
import { GetRandomAlphaNumber, GetLocalStorageData, AddLocalStorageData } from "../../Helpers/Utilities";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CenterService } from "../../WebApiServices/Center.service";
import { PatientService } from "../../WebApiServices/Patient.service";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs, EligibilityCriteria_Msgs } from "../../Helpers/HelperText";
import { useNavigate } from "react-router-dom";



const ChartSelection = () => {

    const navigate = useNavigate();
  const navigatePaths = {
     prevPage: "/PatientScreening",
     currPage: "/ChartSelection",
      nextPage: "/GeneralInformation",
    };
    const [randomAlphabet, setRandomAlphabet] = useState();
    const [CohortTreatment, setCohortTreatment] = useState([]);
    const [crosswalk, setcrosswalk] = useState();
    const [roundId, setroundId] = useState();
    const [uniquePtCount, setUniquePtCount] = useState(0);

    

    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout,isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);
    const pid = GetLocalStorageData("patientId") ?? 0;
  
    let patientData = GetLocalStorageData("patient")
    patientData = patientData && JSON.parse(patientData);
    const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
    const rid = GetLocalStorageData("roundId") ?? patientData?.roundId ?? 0;
    const centerId = patientData?.ptCenterId ?? 0;
    const abstractorId = patientData?.abstractorId ?? 0;
    const surveyDate = patientData?.surveyDate ?? "";

    const centerRoundId = patientData?.centerRoundId ?? false;



    useEffect(() => {
        GenerateRandomAlphabet()
    }, [])
    const GenerateRandomAlphabet = () => {
        setRandomAlphabet(GetRandomAlphaNumber())
    }
    useEffect(() => {

        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {

                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData()
    }, [])


    async function LoadData() {
        try {


            const response = await CenterService.GetRoundData(rid, centerRoundId, pid);//,pid
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                setCohortTreatment(data.centerTreatmentRoundList);
                AddLocalStorageData("ChartSelection", JSON.stringify(data.centerTreatmentRoundList))
                setroundId(rid);
        
                setcrosswalk(data.crossWalkGeneration);
                setUniquePtCount(data.lockedPatientCount)
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }

    const SaveCrossWalk = async () => {
        try {
            ToggleLoader(true)
            const patientModel = {
                patientId: pid ?? 0,
                crosswalk: true
            }

            const response = await PatientService.SavePatient(patientModel);
            if (response?.status === HTTPResponse.OK) {

                setcrosswalk(patientModel.crosswalk)
                ToggleLoader(false)

                return navigate("/GeneralInformation");
            }
            else {
                throw (response.error)
                ToggleLoader(false)
            }

        } catch {
            ToggleLoader(false)
        }
    }


    const SavePage = async () => {



        if (crosswalk == false) {
            const choice = await ShowActionPopUp(
                "Alert", //title
                <div>{renderStyledMessage(uniqueEncryptedPatientId)}</div>,  //message
                "Yes", //Confirm button text
                false
            );
            if (!choice) {
                return;
            } else {
                SaveCrossWalk();
            }
        }
        else
            return navigate("/GeneralInformation");

    }


    const renderStyledMessage = (uniqueEncryptedPatientId) => {
        return (
            <div>
                <span style={{ fontWeight: 'normal',  }}>
                    {formatMessage(EligibilityCriteria_Msgs.CrossWalkPtA, uniqueEncryptedPatientId)}
                </span>
                <br />
                <br />
                <div>
                    <span style={{ fontWeight: 'normal', }}>
                        <u>{formatMessage(EligibilityCriteria_Msgs.CrossWalkPtB, uniqueEncryptedPatientId)}</u> {formatMessage(EligibilityCriteria_Msgs.CrossWalkPtC, "")}
                    </span>
                </div>
               
            </div>
        );
    };

    const formatMessage = (message, patientId) => {
        const parts = message.split("[PatientId]");
        return (
            <>
                {parts[0]}
                <span style={{ fontWeight: 'bold', }}>{patientId}</span>
                {parts[1]}
            </>
        );
    };

    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.ChartSelection} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>

          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>CHART SELECTION</h4>
                            </div>  

                            <div className="survey-introduction-section">
                                <div className="survey-introduction-content">
                                    <div className="instructions">
                                        <ul>
                                            <li className="font-style-spacing">
                                                Patient ID (Scrambled Unique Identifier):{" "}
                                                <label className="font-style-value" htmlFor="">
                                                    {uniqueEncryptedPatientId !== 0 && uniqueEncryptedPatientId}
                                                </label>
                                            </li>
                                            <li className="font-style-spacing">
                                                Center ID (Center Identification Number):{" "}
                                                <label className="font-style-value" htmlFor="">
                                                    {centerId !== 0 && centerId}
                                                </label>
                                            </li>
                                            <li className="font-style-spacing">
                                                Abstractor ID (Numeric Identity of Abstractor):{" "}
                                                <label className="font-style-value" htmlFor="">
                                                    {abstractorId !== 0 && abstractorId}
                                                </label>
                                            </li>
                                            <li className="font-style-spacing">
                                                Date of Chart Data Abstraction Initiation:{" "}
                                                <label className="font-style-value" htmlFor="">
                                                    {surveyDate !== 0 && surveyDate}

                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div >
                                        <div>
                                            <p>
                                                Please see the table below for the target sample sizes and the numbers of completes by your site for the current round of data abstraction. 
                                                <br /><br />
                                            </p>
                                        </div>
                                        <div className="answer-pad-left double-dig-answer">
                                            <div class="table-page">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th rowspan="2">
                                                                Treatment Regimens
                                                            </th>
                                                            <th colspan="5">
                                                                <span style={{ fontSize:'20px' }}> Round {roundId}</span>
                                                            </th>
                                                        </tr>
                                                        <th>Target number of new charts</th>
                                                        <th>Charts satisfying eligibility criteria</th>
                                                        <th>
                                                            Abstracted new charts
                                                            <br/>
                                                            Unique Patient Count: {uniquePtCount}
                                                        </th>
                                                        <th>Number of charts abstracted in previous rounds</th>
                                                        <th>Chart updates</th>
                                                    </thead>
                                                    <tbody>
                                                        {CohortTreatment.map((row) => (
                                                    <tr>
                                                                <td>
                                                                    {row.cohortTreatmentName}
                                                                    {row.cohortTreatmentNameSuperscript == null ? (
                                                                        ''
                                                                    ) : (
                                                                        <>
                                                                                <sup className="supscript">{row.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                                                            {row.cohortTreatmentNameSuperscript.slice(2)}
                                                                        </>
                                                                    )}
                                                        </td>
                                                        <td class="blue-text">
                                                                    {row.newCharts ?? 0}
                                                                </td>
                                                                <td class="blue-text">
                                                                    {row.eligibilityChartUpdates ?? 0}
                                                                </td>
                                                        <td class="blue-text">
                                                                    {row.abstractedNewCharts ?? 0}
                                                        </td>
                                                        <td class="blue-text">
                                                                    {row.abstractedPreviousCharts ?? 0}
                                                        </td>
                                                        <td class="blue-text">
                                                                    {row.ChartUpdates ?? 0}
                                                        </td>
                                                    </tr>
                                                
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <br />
                                        
                                        <div className="refresh-sec">
                                            <div>
                                                <span>
                                                    If your medical center is expected to have more patients than the target sample size determined for a specific abstraction round, a random-selection scheme should be applied. If a randomization method is preferred by your site, please use this approach.{" "}
                                                    <br/><br/>
                                                    Alternatively, please use the random letter generator below to generate a random letter and select a patient whose last name starts with this letter. Please note that each patient will be assigned a random identification code to allow for subsequent extractions of the same patient's data irrespective of whether a random patient selection process is used. If you do not have any patient whose last name starts with the displayed letter which meet the eligibility criteria, click the refresh icon to generate a new random letter.
                                                </span>
                                            </div>
                                            <div>
                                                <span className="refresh-letter">Random letter: {randomAlphabet}</span>{" "}
                                                <a>
                                                    <img
                                                        onClick={GenerateRandomAlphabet}
                                                        width="25px"
                                                        src="../Assets/images/refresh-button.png"
                                                        alt="refresh" style={{ marginBottom:'6px' }} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                                    </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChartSelection;